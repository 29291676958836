export enum DropdownFilterType {
  MultiChecklist = 'MultiChecklist',
  MoneyRange = 'MoneyRange',
  DateRange = 'DateRange',
  AlphabetizedMultiChecklist = 'AlphabetizedMultiChecklist',
}

export type ChecklistItem = {
  label: string
  value: string
}

export type RangeItem = [number | null, number | null]

interface DropdownFilterObject<FiltersState, T extends DropdownFilterType> {
  filterKey: keyof FiltersState
  type: T
  title?: string
}

export interface MultiChecklist<FiltersState>
  extends DropdownFilterObject<
    FiltersState,
    DropdownFilterType.MultiChecklist
  > {
  items: ChecklistItem[]
}

export interface AlphabetizedMultiChecklist<FiltersState>
  extends DropdownFilterObject<
    FiltersState,
    DropdownFilterType.AlphabetizedMultiChecklist
  > {
  items: ChecklistItem[]
}

export interface MoneyRange<FiltersState>
  extends DropdownFilterObject<FiltersState, DropdownFilterType.MoneyRange> {
  range: RangeItem
}

export interface DateRange<FiltersState>
  extends DropdownFilterObject<FiltersState, DropdownFilterType.DateRange> {
  range: RangeItem
}

export type DropdownFilterTypes<FiltersState> =
  | MultiChecklist<FiltersState>
  | AlphabetizedMultiChecklist<FiltersState>
  | MoneyRange<FiltersState>
  | DateRange<FiltersState>

export type FilterList<FiltersState> = {
  readonly title: string
  readonly filters: DropdownFilterTypes<FiltersState>[]
}

export type FilterControls<FiltersState> = {
  readonly filterList: FilterList<FiltersState>[]
  filterState: FiltersState
  defaultFilterState: FiltersState
  updateFilters: (filters: Partial<FiltersState>) => void
}
