import { useRef, useState } from 'react'
import type * as React from 'react'

import { faAngleLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { styled } from 'styled-components'

import { UnstyledButton } from '@b-stock/bstock-next'
import { Button, Modal, FormattedMessage } from '@b-stock/bstock-react'
import { designColors } from '@b-stock/bstock-react/theme'

import type { AuctionSearchContextValue } from '@components/AuctionSearchProvider'
import { StyledScroll } from '@components/scroll'
import ShowResultsButton from '@components/SearchFilters/shared/ShowResultsButton'

import FilterContainer from './shared/FilterContainer'
import { formatTitle } from './shared/filterUtils'
import type {
  ContextType,
  Filter,
  SelectedFilterType,
  AuctionFilterList,
} from './types'

const ModalActions = styled.div``

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
`

export const SModalContent = styled.div`
  height: fit-content;
  overflow-x: auto;
  padding: 1rem;
  ${StyledScroll}
`

export const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 1rem;
  color: ${designColors.primary.default};
`

export const LeftArrow = styled(UnstyledButton)`
  margin-right: 1rem;
`

export const BackButton = ({
  onPress,
}: {
  onPress: () => void
}): React.ReactElement => (
  <LeftArrow onClick={onPress}>
    <StyledIcon icon={faAngleLeft} />
  </LeftArrow>
)

type ModalContentProps<ContextValue extends ContextType, T> = {
  containerRef?: React.RefObject<HTMLDivElement>
  selectedFilter: string
  selectFilter: (filter: SelectedFilterType) => void
  filterShouldDisplaySeparately: boolean
  filters: Filter<T, ContextValue>[]
  searchCtx: ContextValue
}

const ModalContent = <ContextValue extends ContextType, T>({
  containerRef,
  selectedFilter,
  selectFilter,
  filterShouldDisplaySeparately,
  filters: modalFilters,
  searchCtx,
}: ModalContentProps<ContextValue, T>) => {
  const handleClick = (target: HTMLButtonElement) => {
    if (containerRef?.current) {
      containerRef.current.scrollTo({
        top: target.offsetTop - 84,
        behavior: 'smooth',
      })
    }
  }

  if (selectedFilter && filterShouldDisplaySeparately) {
    const FilterComp = modalFilters.find(
      (Filter) => Filter.name === selectedFilter
    )

    if (!FilterComp) {
      throw new Error(
        `Unable to find component with name "${selectedFilter}" in "filters".`
      )
    }
    const props = FilterComp.getOwnProps(searchCtx)

    return <FilterComp {...props} desktopScreen={false} />
  } else {
    return (
      <div>
        {modalFilters.map((FilterComp) => {
          const name = FilterComp.name
          const filterProps = FilterComp.getOwnProps(searchCtx)

          const shouldDisplayFilter =
            Object.keys(filterProps.availableItems || {}).length ||
            Object.keys(filterProps.value || {}).length ||
            Object.keys(filterProps.items || {}).length

          const handleExpand = () => {
            selectFilter({
              filterName: name,
              itemsCount: filterProps.items
                ? Object.keys(filterProps.items).length
                : 0,
            })
          }

          return shouldDisplayFilter ? (
            <FilterContainer
              key={FilterComp.label}
              label={FilterComp.label}
              expandedOnInit={false}
              onExpand={handleExpand}
              afterExpandAnimation={handleClick}
            >
              <FilterComp {...filterProps} desktopScreen />
            </FilterContainer>
          ) : null
        })}
      </div>
    )
  }
}

type FilterModalProps = {
  onClose?: () => void
  onSubmit?: () => void
  closeModal: () => void
  filters: AuctionFilterList
  searchCtx: AuctionSearchContextValue
  sortComp?: React.ReactNode
}

const MobileSearchFilters: React.FC<FilterModalProps> = ({
  onClose,
  onSubmit,
  closeModal,
  sortComp,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  const handleSubmit = () => {
    onSubmit?.()
    closeModal()
  }

  const handleCancel = () => {
    onClose?.()
    closeModal()
  }

  const [selectedFilter, setSelectedFilter] = useState<SelectedFilterType>({
    filterName: '',
    itemsCount: 0,
  })

  const selectFilter = ({ filterName, itemsCount }: SelectedFilterType) => {
    setSelectedFilter({ filterName, itemsCount })
  }
  const handleBackButtonClick = () => {
    setSelectedFilter({
      filterName: '',
      itemsCount: 0,
    })
  }

  const filterShouldDisplaySeparately = !!(
    selectedFilter.filterName && selectedFilter.itemsCount > 8
  )

  return (
    <Modal closeModal={closeModal}>
      <Modal.Header>
        {filterShouldDisplaySeparately ? (
          <>
            <BackButton onPress={handleBackButtonClick} />
            {formatTitle(selectedFilter.filterName)}
          </>
        ) : (
          <FormattedMessage
            id="MobileSearchFilters.header"
            defaultMessage="MobileSearchFilters.header"
          />
        )}
      </Modal.Header>
      <SModalContent ref={containerRef}>
        {sortComp}
        <ModalContent
          filterShouldDisplaySeparately={filterShouldDisplaySeparately}
          selectedFilter={selectedFilter.filterName}
          selectFilter={selectFilter}
          filters={props.filters}
          containerRef={containerRef}
          searchCtx={props.searchCtx}
        />
      </SModalContent>
      <ModalActions>
        <ButtonContainer>
          <Button type="button" appearance="secondary" onClick={handleCancel}>
            <FormattedMessage
              id="MobileSearchFilters.actions.cancel"
              defaultMessage="MobileSearchFilters.actions.cancel"
            />
          </Button>
          <ShowResultsButton
            onSubmit={handleSubmit}
            loading={false}
            results={props.searchCtx.data?.total.toString() || '0'}
          />
        </ButtonContainer>
      </ModalActions>
    </Modal>
  )
}

export default MobileSearchFilters
